import React, {useMemo} from 'react';
import {FlexColumnSection, FlexSection, SmallText} from "../../../styles/globalStyles";
import {getCourseInfoArray, sortCourseEventDays} from "../../../helpers/functions";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useSelector} from "react-redux";
import {t} from "i18next";

const CourseDescription = (props) => {
    const { course } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const {isMobile} = useScreenSize()

    let days = useMemo(() => course ? sortCourseEventDays(course.series_events) : [], [course])
    let info = useMemo(() => course ? getCourseInfoArray(course, false) :  {}, [course])

    if(!course) return <></>

    return (
        <FlexColumnSection gap={'20px'}>
            {Object.values(info).length > 0 &&
            <FlexSection gap={isMobile ? '8px' : '15px'} flex={'unset'} mobile={isMobile}>
                {Object.values(info).map((comp,i) => (
                    <FlexSection gap={isMobile ? '8px' : '15px'} flex={'unset'} mobile={isMobile}>
                        {!isMobile && i > 0 && <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>}
                        {comp}
                    </FlexSection>
                ))}
            </FlexSection>}
            {course.box_categories.bio && <div style={{flex: 1, whiteSpace: 'pre-wrap'}}>{course.box_categories.bio}</div>}
            <FlexColumnSection flex={'unset'}>
                <SmallText weight={'bold'}>{t('course-days-header')}</SmallText>
                {days.map(day => <div>{t(`day-${day.day_of_week}`)}: {dayjs(day.start, 'HH:mm:ss').format(globalLocation.time_format)} - {dayjs(day.end, 'HH:mm:ss').format(globalLocation.time_format)}</div>)}
                {(course.allow_mid_booking && course.past) ? <div>{t('hug-started-expand-label')}</div> : null}
            </FlexColumnSection>
        </FlexColumnSection>
    );
};

export default CourseDescription;